@import '../../styles/globals.scss';

@keyframes animArrow {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  15% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateY(0);
  }
}

.arrow {
  position: absolute;
  bottom: 1rem;
  width: 2rem;
  height: 2rem;
  opacity: 0;
  svg {
    fill: $white;
  }
  &.button {
    cursor: pointer;
  }
}

// Responsive

@media (min-width: $md-width) {
  .arrow {
    width: 3rem;
    height: 3rem;
  }
}
