@import '../../styles/globals.scss';

$sizeSmall: 1rem;
$sizeMedium: 3rem;
$sizeLarge: 4.5rem;

$borderSmall: 2px;
$borderMedium: 4px;
$borderLarge: 6px;

@keyframes animSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.3s;
  &.active {
    opacity: 1;
    transition: opacity 0.3s 0.3s;
  }
  .inner {
    display: inline-block;
    position: relative;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-style: solid;
      border-radius: 50%;
      animation: animSpinner 0.6s cubic-bezier(0.5, 0, 1, 1) infinite;
      border-color: $white transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.225s;
      }
      &:nth-child(2) {
        animation-delay: -0.15s;
      }
      &:nth-child(3) {
        animation-delay: -0.075s;
      }
    }
  }
  // Sizes
  &.small {
    width: $sizeSmall;
    height: $sizeSmall;
    .inner {
      width: $sizeSmall;
      height: $sizeSmall;
      div {
        width: $sizeSmall;
        height: $sizeSmall;
        border-width: $borderSmall;
      }
    }
  }
  &.medium {
    width: $sizeMedium;
    height: $sizeMedium;
    .inner {
      width: $sizeMedium;
      height: $sizeMedium;
      & div {
        width: $sizeMedium;
        height: $sizeMedium;
        border-width: $borderMedium;
      }
    }
  }
  &.large {
    width: $sizeLarge;
    height: $sizeLarge;
    .inner {
      width: $sizeLarge;
      height: $sizeLarge;
      div {
        width: $sizeLarge;
        height: $sizeLarge;
        border-width: $borderLarge;
      }
    }
  }
}
