@import './normalize.scss';
@import './globals.scss';

// Core

html,
body {
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-stretch: normal;
  color: $white;
  background-color: $black;
}

h1 {
  @include font-header;
}
h2 {
  @include font-header-small;
}
h3 {
  @include font-title;
}
h4 {
  @include font-title-small;
}
h1,
h2,
h3,
h4 {
  strong {
    font-weight: 600;
    color: $white;
  }
  &.long {
    font-weight: 400;
    color: $white-60;
    span {
      &.underline {
        text-decoration: underline;
        text-decoration-thickness: 0.1875rem;
        text-decoration-color: $white-60;
        white-space: nowrap;
        color: $white;
      }
      &.square {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin: 0 0.25rem;
        border-radius: 0.125rem;
        transform: translateY(0.125rem);
        background-color: $white;
      }
    }
  }
}
p,
a,
li {
  @include font-body;
  color: $white-80;
  strong {
    font-weight: 600;
    color: $white;
  }
  svg {
    display: inline-block;
    height: 1rem;
    width: auto;
    margin-right: 0.375rem;
    transform: translateY(0.2rem);
  }
}
a {
  @include hover;
}
small {
  @include font-caption;
}
ul {
  margin: 0;
  padding: 0 0 0 1rem;
  list-style: none;
  li {
    position: relative;
    span.dot {
      position: absolute;
      top: 0.1rem;
      left: -1rem;
    }
  }
}
svg {
  fill: $white;
}
hr {
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid $white-60;
}
span.show {
  display: none;
}

// Layout

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  transition: background-color 0.6s;
  .loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
  }
  .card {
    position: relative;
    width: 100%;
    height: 66vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .group {
    width: 100%;
    position: relative;
    margin-bottom: 6rem;
  }
  .row {
    position: relative;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    &.max {
      max-width: $row-max-width;
    }
    &.center {
      text-align: center;
    }
    .col {
      position: relative;
      flex: 1;
      margin: 0 1rem 2rem;
      max-width: 1200px;
      min-width: 160px;
      &.center {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  span.show.md {
    display: inline;
  }
  h1 {
    font-size: 3rem;
  }
  .container {
    .card {
      height: 100vh;
    }
  }
}

@media (min-width: $lg-width) {
  span.show.lg {
    display: inline;
  }
}

@media (min-width: $xlg-width) {
  span.show.xlg {
    display: inline;
  }
}
