@import '../../styles/globals.scss';

// Animations

@keyframes animDot {
  0% {
    opacity: 0;
    transform: scale(0.33);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes animBlink {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes animBtnOutline {
  0% {
    opacity: 0;
    transform: scale(1);
    border-color: $primary;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    border-color: $primary;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
    border-color: $tertiary;
  }
}

// Layout

.container {
  &.folio {
    h1,
    h2,
    h3,
    h4 {
      &.long {
        color: $white-80;
      }
    }
    .hero {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-height: 90vh;
      pointer-events: none;
      .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      .inner {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin: 2rem 0 3rem;
        // background-color: yellow;
        img,
        video {
          max-width: 90vw;
          max-height: 60vh;
          height: auto;
        }
      }
      .text {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.overlay {
          text-shadow: $textShadow;
          svg {
            filter: $dropShadow;
          }
        }
        .overlay-gradient {
          position: absolute;
          top: -10rem;
          left: 0;
          bottom: 0;
          right: 0;
        }
        .text-inner {
          position: relative;
          max-width: 640px;
          text-align: center;
          padding: 0 1rem;
          margin-bottom: 3rem;
          svg {
            height: 4rem;
            width: auto;
          }
        }
      }
      .loading {
        position: absolute;
        .icon {
          opacity: 0;
          transition: opacity 0.3s;
          &.active {
            opacity: 1;
            transition: opacity 0.3s;
          }
          svg {
            height: 10vh;
            width: auto;
            fill: $white-10;
            animation: animBlink 1s infinite;
          }
        }
      }
    }
    .summary {
      width: 100%;
      position: relative;
      padding: 8rem 0 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 6rem;
        border: 2px solid $white;
        border-radius: 0.5rem;
        margin-bottom: 2rem;
        svg {
          width: 66%;
          height: 66%;
        }
      }
      h4 {
        margin-bottom: 2rem;
      }
    }
    .process {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 8rem 0 6rem;
      .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      .row {
        flex-direction: column;
        .col.step {
          min-width: auto;
          padding-left: 2rem;
          .dot {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            top: 0;
            left: -0.5rem;
            .node {
              position: absolute;
              width: 1rem;
              height: 1rem;
              border-radius: 999rem;
              background-color: $white;
              &.highlight {
                width: 3rem;
                height: 3rem;
                background-color: $white-60;
                opacity: 0;
                animation: animDot 2s infinite;
              }
            }
            &.dashed {
              .node {
                background: none;
                border: 2px $white-40 solid;
                box-sizing: border-box;
              }
            }
          }
        }
        .line {
          position: absolute;
          top: 1.5rem;
          bottom: -2.5rem;
          left: 0.4125rem;
          border-left: 2px solid $white;
          &.dashed {
            border-left: 2px dashed $white-40;
          }
        }
        h4,
        p {
          margin-bottom: 0;
          &.year {
            color: $white-60;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      pointer-events: none;
      .item {
        flex: 1;
        flex-basis: 100%;
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: auto;
          margin-bottom: 1rem;
        }
        .text {
          display: flex;
          flex-direction: column;
          padding: 0 2rem 2rem;
          text-align: center;
          h3 {
            margin-bottom: 0.75rem;
          }
        }
      }
    }
    footer {
      margin-top: 8rem;
    }
  }
  .btn-wrapper {
    display: inline-block;
    position: relative;
    margin-bottom: 1.5rem;
    .outline {
      position: absolute;
      left: 0;
      right: 0;
      top: -12%;
      bottom: -12%;
      border-radius: 999rem;
      border: 2px solid $primary;
      opacity: 0;
    }
    .btn {
      margin-bottom: 0;
    }
    &.active {
      .outline {
        animation: animBtnOutline 2s linear infinite;
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  .container {
    &.folio {
      .hero {
        min-height: 100vh;
        .inner {
          margin: 2rem 0;
        }
        .text-inner {
          .inner {
            padding: 0 2rem;
          }
        }
      }
      .process {
        .row {
          flex-direction: row;
          .col.step {
            padding-left: 0;
            .dot {
              position: relative;
              top: -0.5rem;
              left: -0.5rem;
            }
            .line {
              top: 0.5rem;
              bottom: auto;
              left: 1rem;
              right: -2rem;
              border-left: none;
              border-bottom: 2px solid $white;
              &.dashed {
                border-bottom: 2px dashed $white-40;
              }
            }
          }
        }
      }
      .grid {
        .item {
          flex-basis: 33.33%;
          max-width: 33.33%;
        }
      }
    }
  }
}

@media (min-width: $xlg-width) {
  .container {
    &.folio {
      .grid {
        .item {
          flex-basis: 25%;
          max-width: 25%;
        }
      }
    }
  }
}
