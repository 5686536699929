@import '../../styles/globals.scss';

button {
  &.btn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
    height: 3rem;
    border: none;
    border-radius: $formInputBorderRadius;
    text-align: center;
    text-decoration: none;
    color: $black;
    background-color: $white;
    @include hover-button;
    @include font-body-demi;
    svg {
      width: 1rem;
      height: auto;
      margin-right: 0.5rem;
      fill: $white;
    }
    &.icon {
      padding: 0;
      width: 3rem;
      border-radius: 999rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0;
      }
    }
    &.secondary {
      background: none;
      border: 2px solid $white-40;
    }
    &.small {
      padding: 0rem 1rem;
      height: 2.25rem;
      @include font-caption;
      svg {
        width: 0.875rem;
      }
      &.icon {
        padding: 0;
        width: 2.25rem;
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    &.disabled {
      opacity: 0.4;
      &:hover {
        transform: scale(1);
        filter: brightness(1);
        cursor: not-allowed;
      }
    }
  }
}
