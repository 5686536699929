@import '../../../styles/globals.scss';

// Animation

@keyframes animColor {
  0% {
    fill: $primary;
  }
  25% {
    fill: $secondary;
  }
  50% {
    fill: $tertiary;
  }
  75% {
    fill: $secondary;
  }
  100% {
    fill: $primary;
  }
}

@keyframes animSkills {
  0% {
    opacity: 0;
    transform: rotate(-22.5deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

// Layout

#tooltip {
  position: absolute;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: $black;
  border: none;
  border-radius: 0.25rem;
  pointer-events: none;
  transition: opacity 0.3s;
  p {
    margin-bottom: 0;
    color: $white;
  }
}

.container {
  &.cv {
    .card {
      height: 90vh;
    }
    .hand-events {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      touch-action: pan-y;
    }
    .hand {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
    }
    .skills {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      .profile {
        position: absolute;
        width: 25%;
        height: 25%;
        border-radius: 999rem;
        overflow: hidden;
        cursor: pointer;
        .image {
          border-radius: 999rem;
          overflow: hidden;
        }
      }
      svg {
        overflow: visible;
        g.node {
          filter: brightness(1);
          transition: filter 0.3s;
          &:hover {
            filter: brightness(0.6);
            transition: filter 0.3s;
            cursor: pointer;
          }
        }
      }
    }
    .row {
      &.intro {
        h1 {
          &.long {
            span {
              color: $white;
            }
          }
        }
      }
      &.experiences {
        margin-top: 1rem;
        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
        h4,
        p {
          margin-bottom: 0;
          &.year {
            color: $white-60;
            margin-bottom: 0.25rem;
          }
        }
      }
      &.projects {
        margin: 4rem 0;
        .grid {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          pointer-events: none;
          pointer-events: all;
          .item {
            display: flex;
            flex-direction: column;
            margin: 0 1rem 1rem;
            position: relative;
            border-radius: 0.25rem;
            overflow: hidden;
            background-color: $white-10;
            transform: scale(1);
            transition: transform 0.3s, background-color 0.3s;
            .outline {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              box-sizing: border-box;
              border-radius: 0.25rem;
              border: 2px solid $white;
              opacity: 0;
              transition: opacity 0.3s;
            }
            &:hover {
              transform: scale(0.98);
              background-color: $white-5;
              transition: transform 0.15s, background-color 0.15s;
              cursor: pointer;
              .outline {
                opacity: 1;
                transition: opacity 0.3s;
              }
            }
            img {
              width: 100%;
              height: auto;
              margin-bottom: 1rem;
            }
            .text {
              display: flex;
              flex-direction: column;
              padding: 0 1rem 0.5rem;
              text-align: center;
              h3 {
                margin-bottom: 0.75rem;
              }
            }
          }
        }
      }
      &.process {
        margin-top: 1rem;
        h4 {
          position: relative;
          display: inline-block;
          padding: 0.5rem 1rem;
          background-color: $black;
          border: 2px solid $white;
          border-radius: 0.25rem;
          margin-bottom: 1rem;
        }
        .line {
          display: none;
          position: absolute;
          top: 1.75rem;
          left: 0;
          right: -4rem;
          border-bottom: 2px dashed $white-40;
        }
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  .container {
    &.cv {
      .card {
        height: 100vh;
      }
      .hand {
        width: 6rem;
        height: 6rem;
      }
      .skills {
        width: 60vw;
      }
      .row {
        &.projects {
          .grid {
            .item {
              margin: 0 0.5rem 1rem;
              flex-basis: 31%;
              max-width: 31%;
              .text {
                padding: 0 2rem 2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $lg-width) {
  .container {
    &.cv {
      .row {
        &.process {
          .line {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: $xlg-width) {
  .container {
    &.cv {
      .skills {
        width: 40vw;
      }
      .row {
        &.projects {
          .grid {
            .item {
              flex-basis: 23%;
              max-width: 23%;
            }
          }
        }
      }
    }
  }
}
