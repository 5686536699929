// Colours

$primary: #ff005c;
$secondary: #a000ff;
$tertiary: #0038ff;

$white: #fff;
$black: #000;
$grey-1: #1e1e1e;

$white-100: $white;
$white-90: rgba($white, 0.9);
$white-80: rgba($white, 0.8);
$white-70: rgba($white, 0.7);
$white-60: rgba($white, 0.6);
$white-50: rgba($white, 0.5);
$white-40: rgba($white, 0.4);
$white-30: rgba($white, 0.3);
$white-20: rgba($white, 0.2);
$white-10: rgba($white, 0.1);
$white-5: rgba($white, 0.05);
$white-0: rgba($white, 0);
$black-100: $black;
$black-90: rgba($black, 0.9);
$black-80: rgba($black, 0.8);
$black-70: rgba($black, 0.7);
$black-60: rgba($black, 0.6);
$black-50: rgba($black, 0.5);
$black-40: rgba($black, 0.4);
$black-30: rgba($black, 0.3);
$black-20: rgba($black, 0.2);
$black-10: rgba($black, 0.1);
$black-5: rgba($black, 0.05);
$black-0: rgba($black, 0);

// Typography

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&family=Poppins:wght@400;600;700&display=swap');

@mixin font-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 130%;
  margin-bottom: 1rem;
}
@mixin font-header-small {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 130%;
  margin-bottom: 0.75rem;
}
@mixin font-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  margin-bottom: 1rem;
}
@mixin font-title-small {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
  margin-bottom: 1rem;
}
@mixin font-body {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 1rem;
}
@mixin font-body-demi {
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 1rem;
}
@mixin font-caption {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 0.875rem;
}

// Animations

@keyframes animFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animRise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// Hover

@mixin hover {
  opacity: 1;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
    transition: opacity 0.15s;
    cursor: pointer;
  }
}
@mixin hover-button {
  transform: scale(1);
  filter: brightness(1);
  transition: transform 0.3s, filter 0.3s;
  &:hover {
    transform: scale(0.96);
    filter: brightness(0.8);
    transition: transform 0.15s, filter 0.15s;
    cursor: pointer;
  }
}

// Layout

$formBorderRadius: 0.25rem;
$formInputBorderRadius: 999rem;
$textShadow: 0 0.25rem 0.5rem $black-60;
$dropShadow: drop-shadow(0 0.25rem 0.5rem $black-60);

// Responsive

$xsm-width: 320px;
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xlg-width: 1200px;

$row-max-width: 1280px;
