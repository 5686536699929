@import '../../styles/globals.scss';

// Layout

.slider {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8rem;
  cursor: grab;
  &.single {
    cursor: auto;
  }
  &.active {
    cursor: grabbing;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .inner {
    position: relative;
    overflow: hidden;
    touch-action: none;
    margin: 2rem 0;
    .slides {
      display: inline-flex;
      flex-direction: row;
      pointer-events: none;
      user-select: none;
      .slide {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        overflow: hidden;
        // background-color: yellow;
        img,
        video {
          max-width: 95vw;
          max-height: 75vh;
          height: auto;
        }
      }
    }
  }
  .left,
  .right {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8rem;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: $white;
      opacity: 1;
      transition: opacity 0.3s;
    }
    &.invert {
      svg {
        fill: $black;
      }
    }
    &.active {
      opacity: 1;
      transition: opacity 0.3s;
      cursor: pointer;
    }
    &:hover svg {
      opacity: 0.6;
      transition: opacity 0.15s;
      cursor: pointer;
    }
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .pagination {
    position: relative;
    .pagination-inner {
      display: flex;
      padding: 0 1rem 2rem;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      pointer-events: all;
      cursor: pointer;
      overflow-x: scroll;
      overscroll-behavior-x: contain;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        padding: 0 0.5rem;
        border-radius: 999rem;
        opacity: 1;
        transition: background-color 0.3s, opacity 0.3s;
        p {
          text-align: center;
          white-space: nowrap;
          font-weight: 500;
          margin-bottom: 0;
          color: $white-80;
          user-select: none;
          transition: color 0.3s;
        }
        .dot {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 999rem;
          background-color: $white-10;
          transition: background-color 0.3s;
        }
        &:hover {
          opacity: 0.6;
          transition: background-color 0.3s, opacity 0.15s;
        }
        &.title {
          margin: 0 0.25rem;
          padding: 0 0.75rem;
          background-color: $white-10;
        }
        &.active {
          &.title {
            background-color: $white;
          }
          p {
            color: $black;
            transition: color 0.3s;
          }
          .dot {
            background-color: $white;
            transition: background-color 0.3s;
          }
          &:hover {
            opacity: 1;
          }
        }
        &.invert {
          &.title {
            background-color: $black-10;
          }
          p {
            color: $black-80;
          }
          .dot {
            background-color: $black-10;
          }
          &.active {
            &.title {
              background-color: $black;
            }
            p {
              color: $white;
              transition: color 0.3s;
            }
            .dot {
              background-color: $black;
              transition: background-color 0.3s;
            }
          }
        }
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  .slider {
    .left,
    .right {
      display: flex;
    }
    .inner {
      margin: 6rem 0 4rem;
    }
    .pagination {
      .pagination-inner {
        justify-content: center;
      }
    }
  }
}
