@import '../../styles/globals.scss';

.video {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  video {
    object-fit: cover;
  }
  &.fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.active {
    opacity: 1;
    transition: opacity 0.3s;
  }
}
