@import '../../../styles/globals.scss';

.container {
  &.sleeping {
    min-height: auto;
    opacity: 0;
    animation: animFade 1s forwards;
    .card {
      height: 85vh;
    }
    .zzz-events {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      touch-action: pan-y;
    }
    .zzz {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
    }
    h4 {
      margin-bottom: 1.5rem;
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  .container {
    &.sleeping {
      .card {
        height: 100vh;
      }
      .zzz {
        width: 8rem;
        height: 8rem;
      }
    }
  }
}
