@import '../../styles/globals.scss';

// Layout

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black-60;
    opacity: 0;
    transition: opacity 0.3s;
    &.active {
      pointer-events: all;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
  .box {
    width: 90vw;
    height: 100%;
    background-color: $black-80;
    border-right: 0.25rem solid $white-20;
    transform: translateX(-90vw);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    overflow-y: scroll;
    &.active {
      pointer-events: all;
      transform: translateX(0);
      opacity: 1;
      transition: opacity 0.15s, transform 0.15s;
    }
    .inner {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 2rem;
      .intro {
        margin-bottom: 1rem;
        h4 {
          font-size: 1rem;
          font-weight: 400;
          color: $white-80;
          strong {
            font-weight: 700;
            color: $white;
          }
        }
      }
      ul {
        padding: 0;
        margin-bottom: 2rem;
        li {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.75rem 0;
          margin-bottom: 0;
          .line {
            position: absolute;
            left: -2rem;
            width: 0.25rem;
            height: 100%;
            transition: width 0.3s;
          }
          span {
            @include font-title;
            margin-bottom: 0;
            color: $white-100;
            opacity: 1;
            transition: opacity 0.3s;
            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.75rem;
            }
          }
          &.active {
            .line {
              width: 1rem;
              transition: width 0.3s;
            }
          }
          &:hover {
            cursor: pointer;
            span {
              opacity: 0.6;
              transition: opacity 0.3s;
            }
          }
        }
      }
    }
  }
  .action {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
    padding: 1rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
    h4 {
      margin: 0 0 0 0.75rem;
      user-select: none;
      color: $white-60;
      opacity: 0;
      transition: opacity 0.3s;
      &.active {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  .menu {
    .box {
      width: 40vw;
      transform: translateX(-40vw);
      .inner {
        padding: 0 4rem;
        .intro {
          h4 {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
