@import '../../styles/globals.scss';

// Animations

@keyframes animTextIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animIdeasIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(0.2rem);
  }

  75% {
    opacity: 1;
    transform: scale(1.02) translateY(0.2rem);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0.2rem);
  }
}

@keyframes animButtonIn {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animLogoIn {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animContactButton {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

// Layout

.home-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container {
  &.home {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    h1 {
      font-weight: 600;
      margin-bottom: 2rem;
    }
    .logo {
      height: 6rem;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
      max-width: 72rem;
      opacity: 0;
      transition: opacity 0.15s;
      &.active {
        opacity: 1;
        transition: opacity 0.15s;
      }
      h1 span {
        &.ideas svg {
          height: 2.4rem;
          width: auto;
          opacity: 0;
          animation: animIdeasIn 0.3s 1.2s ease-out forwards;
        }

        &.word {
          opacity: 0;
          transition: opacity 0.3s;

          &.active {
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }
      .partners {
        margin-bottom: 1.2rem;
        svg {
          height: 2.1em;
          width: auto;
          margin: 0.4rem;
          opacity: 0;
          animation: animLogoIn 0.3s 1.2s ease-out forwards;
        }
      }
    }

    .contact-button {
      width: 3rem;
      height: 3rem;
      padding: 0.2rem 4.8rem;
      border-radius: 999rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      pointer-events: auto;
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s;
      animation: animButtonIn 0.3s ease-out forwards;
      @include hover-button;
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 999rem;
        z-index: -1;
      }
      p {
        line-height: 0;
        margin: 0;
        flex-shrink: 0;
        font-size: 1.2rem;
        font-weight: 500;
        opacity: 1;
        transition: opacity 0.3s;
        color: $white;
      }
    }
  }
}

// Responsive

// Larger than tiny portrait devices (portrait phones, 321px and up)
@media (min-width: $xsm-width) {
  .container {
    &.home {
      .logo {
        height: 8rem;
      }
      .content {
        .partners {
          svg {
            height: 2.6rem;
            margin: 0.4rem 0.8rem;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $sm-width) {
  .container {
    &.home {
      .logo {
        height: 10rem;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $md-width) {
  .container {
    &.home {
      .content {
        .partners {
          svg {
            height: 3.6rem;
            margin: 0.6rem 1.8rem;
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $lg-width) {
  .container {
    &.home {
      .content {
        h1 span {
          &.ideas svg {
            height: 3rem;
          }
        }
      }
      .contact-button {
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $xlg-width) {
  .container {
    &.home {
      .logo {
        height: 12rem;
      }
    }
  }
}
