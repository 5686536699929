@import '../../styles/globals.scss';

footer {
  width: 100%;
  p {
    margin-bottom: 0.75rem;
    a {
      text-decoration: none;
    }
  }
  h4 {
    margin-bottom: 1.5rem;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    &.first {
      justify-content: flex-end;
    }
    .left,
    .right {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      opacity: 1;
      transition: opacity 0.3s;
      p {
        font-weight: 500;
        margin-bottom: 0;
        color: $white-60;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $white;
        transform: translateX(0);
        transition: transform 0.3s;
      }
      &:hover {
        opacity: 0.6;
        transition: opacity 0.15s;
        cursor: pointer;
        svg {
          transition: transform 0.3s;
        }
      }
    }
    .left {
      svg {
        margin-right: 0.5rem;
      }
      &:hover {
        svg {
          transform: translateX(-0.5rem);
        }
      }
    }
    .right {
      p {
        margin-right: 0.5rem;
      }
      &:hover {
        svg {
          transform: translateX(0.5rem);
        }
      }
    }
  }
}

// Responsive

@media (min-width: $md-width) {
  footer {
    .pagination {
      margin: 0 1rem 2rem;
    }
  }
}
